import React, { useState } from 'react';
import './App.css';

function App() {
    const [email, setEmail] = useState('');
    const [feature, setFeature] = useState('');

    const handleEmailChange = (event) => {
        setEmail(event.target.value);
    };

    const handleFeatureChange = (event) => {
        setFeature(event.target.value);
    };

    const handleSubmit = async (event) => {
        event.preventDefault();

        // validate email and feature
        if (!email) {
            alert('Please enter your email!');
            return;
        }
        const response = await fetch('http://vantphan.com:80/send-email', {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
            },
            body: JSON.stringify({ email: email, feature: feature }),
        });

        if (response.ok) {
            alert('Email sent! Thank you for joining our waiting list!');
        } else {
            alert('Failed to send email. Please try again later.');
        }
    };

    return (
        <div className="App">
            <div className="landing-container">
                <header className="landing-header">
                    <h1>Streamline Your Job Search!</h1>
                    <p>Track your applications, craft perfect cover letters, and manage your communications—all in one place.</p>
                </header>
                <section className="why-use-app">
                    <h2>Why Use Our App?</h2>
                    <p>Save time by managing all applications in one interface.</p>
                    <p>Improve your cover letters with AI-powered suggestions.</p>
                    <p>Keep track of follow-ups and responses effortlessly.</p>
                </section>
                <section className="waiting-list">
                    <h2>Join Our Waiting List for Early Access</h2>
                    <p>Sign up today and get the first version of our app for free!</p>
                    <form onSubmit={handleSubmit}>
                        <input type="email" placeholder="Enter your email address" onChange={handleEmailChange}/>
                        <input type="text" placeholder="What feature are you interested in?" onChange={handleFeatureChange}/>
                        <button type="submit">Join Waiting List</button>
                        <footer className="landing-footer">
                            <p>Contact me at <a href="mailto:vanthuyphan@gmail.com">vanthuyphan@gmail.com</a></p>
                        </footer>
                    </form>
                </section>
            </div>
        </div>
    );
}

export default App;
